@use '~@amzn/awsui-design-tokens/polaris' as awsui;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    padding: 0;
    margin: 0;
}

div.section {
    &:first-of-type {
        margin-top: 30px;
    }

    margin-bottom: 30px;
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 100ms;
    }
}

.foo {
    flex: 1;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.float-right {
    float: right;
}

.fullWidth {
    width: 100%;
}

.ninetyWidth {
    width: 90%
}

.stDeviceIcon {
    position: relative;
    top: 0;
    left: 0;
    //border: 1px red solid;
    //align-items: center;
    vertical-align: middle;
}

.statusTreeChild0 {
    margin-left: 10px;
    border-bottom: none;
}

.statusTreeChild1 {
    margin-left: 20px;
    border-bottom: none;
}

.statusTreeChild2 {
    margin-left: 40px;
}

.statusTreeItem {
    border-top: none;
    margin-bottom: 5px;
    vertical-align: middle;
}

.invert {
    -webkit-filter: invert(1);
    filter: invert(1);
}