.expandableDeviceContainer {
    cursor: pointer;
    padding: 5px;
    width: 100%;
    text-align: left;
    outline: none;
}


.expandableDeviceEditInput {
    font-size: large;
    padding: 4px;
    padding-bottom: 2px;
    padding-left: 6px;
    width: 400px
}

.expandableDeviceCheckbox {
    width: 1.3em;
    height: 1.3em;
}


/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
/*.expanded, .collapsible:hover {
    background-color: #ccc;
}*/

/* Style the collapsible content. Note: hidden by default */
.expandableDeviceContent {
    padding: 0;
    display: none;
    overflow: hidden;
}